export const homeObjOneCon = {
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'WANT TO GET IN CONTACT? BELOW ARE OUR DETAILS.',
    headline: 'CONTACT INFORMATION',
    description:
      '',
    buttonLabel: 'Shop Now',
    imgStart: '',
    img: 'images/logo3.jpg',
    alt: 'Credit Card',
    email: 'anovuyomedical',
    contactName: 'Anovuyo Siswana',
    contactNumber: '062 969 4376',
    Addressln1: '108 Victoria Street',
    Addressln2: 'Brakpan',
    Addressln3: '',
  };