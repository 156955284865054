import React from 'react';
import './Footer.css';
import { Button } from '../../Button';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';
import { MdFingerprint } from 'react-icons/md';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
        Anovuyo Medical Projects's is SAHPRA Approved
        </p>

      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/'>More Information</Link>

          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact-us'>Contact Us</Link>

          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Services</h2>
            <Link to='/services'>What We Do</Link>

          </div>
          <div className='footer-link-items'>
            <h2>Anovuyo Siswana</h2>
            <Link to='/contact-us'>062 969 4376</Link>

          </div>
          
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <MdFingerprint className='navbar-icon' />
              Anovuyo Medical Projects's
            </Link>
          </div>
          <small className='website-rights'>Anovuyo Medical Projects's © 2022</small>

        </div>
      </section>
    </div>
  );
}

export default Footer;
